import React, { Component } from 'react';
import '../styles/styles.scss';

import { ReactComponent as PhoneIcon } from '../assets/phone.svg';
import { ReactComponent as MailIcon } from '../assets/mail.svg';
import { ReactComponent as MapIcon } from '../assets/map.svg';
import { ReactComponent as InstagramIcon } from '../assets/instagram.svg';

const texts = {
    carrier: 'Globe/TM: ',
    phoneNumber: '+63-915-357-2283',
    emailAddress: 'askretreat@gmail.com',
    address: 'Brgy. Malinao, General Luna, Siargao Island, Surigao del Norte, 8149 Philippines',
    dSmiths: 'this website is made with LOVE for Siargao island',
    dSmithsContact: 'by D.Smiths, 2020.',
}  

const styles = {
    container: {
        width: '100%', height: 'auto',
        backgroundColor: '#000', 
        padding: 0, paddingTop: 10, paddingBottom: 200,
        textAlign: 'left', 
    },
        leftWrap: {
            marginLeft: 20,
            display: 'inline-block',
            width: '10%',
            verticalAlign: 'top',
        },
            phoneIcon: { 
                display: 'block', fill: '#fff', width: 20, height: 32,
                marginTop: -4,
            },
            mailIcon: {
                display: 'block', fill: '#fff', width: 18, height: 26,
            },
            mapIcon: {
                display: 'block', fill: '#fff', width: 16, height: 20,
                marginTop: 5, marginLeft: 1,
            },
        middleWrap: {
            display: 'inline-block',
            width: '60%', 
            verticalAlign: 'top',
        },
                paragraph: {
                    minHeight: 30,
                },
                    text: {
                        textDecoration: 'none',
                        fontSize: 14, lineHeight: '20px', color: '#888',
                    },
        rightWrap: {
            display: 'inline-block',
            marginRight: 20, float: 'right',
            verticalAlign: 'top',
        },
            instaIcon: {
                width: 50, height: 50,
            },

    dSmiths:{
        marginTop: 40,
    },
        greetingsText:{
            textDecoration: 'none',
            fontSize: 14, lineHeight: '20px', color: '#ddd',
        },
        dSmithsContact:{
            textDecoration: 'none', fontWeight: 600,
            fontSize: 14, lineHeight: '20px', color: 'rgba(241, 102, 102, 1)',
        }
}


class Contacts extends Component {
    
    render(){
        return(
            <div style={styles.container}>
                <div style={styles.leftWrap}>
                    <PhoneIcon style={styles.phoneIcon} />
                    <MailIcon style={styles.mailIcon} />
                    <MapIcon style={styles.mapIcon} />
                </div>   
                <div style={styles.middleWrap}>
                    <div style={styles.paragraph}>
                        <span style={styles.text}>{texts.carrier}</span>
                        <a style={styles.text} href='tel:639153572283'>{texts.phoneNumber}</a>
                    </div>
                    <div style={styles.paragraph}>
                        <a 
                            style={styles.text} 
                            href='mailto:askretreat@gmail.com'  
                            target='_blank' 
                            rel="noopener noreferrer"
                        >
                            {texts.emailAddress}
                        </a>
                    </div>
                    <div style={styles.paragraph}>
                        <a 
                            style={styles.text} 
                            href='https://goo.gl/maps/vRLDYzbd4aYzMvaq9' 
                            target='_blank' 
                            rel="noopener noreferrer"
                        >
                            {texts.address}
                        </a>
                    </div>
                    <div style={styles.dSmiths}>
                        <p style={styles.greetingsText}>{texts.dSmiths}</p>
                        <a 
                            style={styles.dSmithsContact} 
                            href="mailto:jibong.koo@gmail.com?subject=Hi, D.Smiths&body=I am sending this mail from 'retreat siargao resort' website..."
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            {texts.dSmithsContact}
                        </a>
                    </div>
                </div>
                <a style={styles.rightWrap} href='https://www.instagram.com/retreatsiargao/' target='_blank' rel="noopener noreferrer">
                    <InstagramIcon style={styles.instaIcon} />
                </a>     
                
            </div>
    );
  }
}


export default Contacts;
