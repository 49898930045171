import React, { Component } from 'react';
import '../styles/styles.scss';

import { FormattedMessage } from 'react-intl';

import { ReactComponent as HomeIcon } from '../assets/home.svg';
import { ReactComponent as RoomsIcon } from '../assets/rooms.svg';
import { ReactComponent as FoodIcon } from '../assets/food.svg';
import { ReactComponent as ActivityIcon } from '../assets/activity.svg';
import { ReactComponent as MediaIcon } from '../assets/media.svg';
import { ReactComponent as ContactIcon } from '../assets/contact.svg';
import { ReactComponent as PhoneIcon } from '../assets/phone.svg';
import { ReactComponent as MailIcon } from '../assets/mail.svg';
import { ReactComponent as MapIcon } from '../assets/map.svg';

class TabBar extends Component {
    
    handleScreenChange = ( screen ) => {
        this.props.handleScreenChange( screen );
    }
    
    onMediaButtonPress =  () => {
        this.props.showCarouselModal();
        // if ( this.props.showPhotos ){
        //     this.props.showCarouselModal();
        // }else {
        //     this.props.showModal({
        //         number: this.props.internetSpeed,
        //         title: <FormattedMessage id='seconds' defaultMessage='seconds' />,
        //         description: <FormattedMessage id='internetSpeedDesc' defaultMessage="Due to slow Internet speed, it will a while to load all the photos. Do you still want to load the photos?" />,
        //         actions:[
        //             { 
        //                 label: <FormattedMessage id='agree' defaultMessage='Yes, I can wait.' />,
        //                 onPress: this.props.showCarouselModal
        //             }
        //         ],
        //     });
        // }
    }

    onContactButtonPress = () => {
        this.props.showModal({
            title: 'Contacts',
            description: 'We love to hearing from you. Please ask us any question. You also could reach us by...',
            actions:[
                { 
                    icon: <PhoneIcon className='action-icon' 
                        style={{widht: 20, height: 17, marginTop: 12}} />,
                    description: 'Globe/TM: +63-915-357-2283',
                    onPress: () => { window.open('tel:639153572283') }
                },
                { 
                    icon: <MailIcon className='action-icon' 
                        style={{width: 19, height: 12, marginTop: 15}} />,
                    description: 'askretreat@gmail.com',
                    onPress: () => { window.open('mailto:askretreat@gmail.com') }
                },
                { 
                    icon: <MapIcon className='action-icon' 
                        style={{widht: 12, height: 17, marginTop: 12}} />,
                    description: 'Brgy. Malinao, General Luna, Siargao Island, Surigao del Norte, 8149 Philippines',
                    onPress: () => { window.open('https://goo.gl/maps/vRLDYzbd4aYzMvaq9', '_blank') }
                },
            ]
        });
    }

    render(){
        const { currentScreen } = this.props;
        const { handleScreenChange } = this;

        const defaultStyle = {
            background: { backgroundColor: 'transparent' },
            icon: { fill: '#000' },
            label: { color: '#000' }
        }
        const highlightStyle = {
            background: { backgroundColor: '#F5A623' },
            icon: { fill: '#000' },
            label: { color: '#000' }
        };

        let homeButtonStyle = defaultStyle, 
            roomsButtonStyle = defaultStyle, 
            foodButtonStyle = defaultStyle, 
            activityButtonStyle = defaultStyle;

        if ( currentScreen === 'home' ) homeButtonStyle = highlightStyle;
        else if ( currentScreen === 'rooms' ) roomsButtonStyle = highlightStyle;
        else if ( currentScreen === 'food' ) foodButtonStyle = highlightStyle;
        else if ( currentScreen === 'activity' ) activityButtonStyle = highlightStyle;

        return(
            <div className="tab-bar-container">
                <div className="contents" >
                    <div className="home-button button"
                        style={homeButtonStyle.background}
                        onClick={()=>handleScreenChange('home')}>
                        <HomeIcon className="button-icon" style={homeButtonStyle.icon} />
                        <p style={homeButtonStyle.label}><FormattedMessage id="home" defaultMessage="Home"/></p>
                    </div>
                    <div className="rooms-button button" 
                        style={roomsButtonStyle.background}
                        onClick={()=>handleScreenChange('rooms')}>
                        <RoomsIcon className="button-icon" style={roomsButtonStyle.icon} />
                        <p style={roomsButtonStyle.label}><FormattedMessage id="rooms" defaultMessage="Rooms"/></p>
                    </div>
                    <div className="food-button button" 
                        style={foodButtonStyle.background}
                        onClick={()=>handleScreenChange('food')}>
                        <FoodIcon className="button-icon" style={foodButtonStyle.icon} />
                        <p style={foodButtonStyle.label}><FormattedMessage id="food" defaultMessage="Food"/></p>
                    </div>
                    <div className="activity-button button" 
                        style={activityButtonStyle.background}
                        onClick={()=>handleScreenChange('activity')}>
                        <ActivityIcon className="button-icon" style={activityButtonStyle.icon} />
                        <p style={activityButtonStyle.label}><FormattedMessage id="activity" defaultMessage="Activity"/></p>
                    </div>
                    <div className="media-button button" onClick={ () => this.onMediaButtonPress() }>
                        <MediaIcon className="button-icon" style={{fill:'#000'}} />
                        <p><FormattedMessage id="gallery" defaultMessage="Gallery"/></p>
                    </div>
                    <div className="contact-button button" onClick={ () => this.onContactButtonPress() }>
                        <ContactIcon className="button-icon" style={{fill:'#000'}} />
                        <p><FormattedMessage id="contact" defaultMessage="Contact"/></p>
                    </div>
                </div>
            </div>
        );
    }
}

export default TabBar;
