import React, { Component } from 'react';
import { ReactComponent as CloseIcon } from '../assets/close.svg';

class PhotoViewer extends Component {

    render(){

        return(
            <div style={styles.photoViewerContainer}>
                <div style={styles.closeButtonWrap} onClick={this.props.closePhotoViewer}>
                    <CloseIcon fill='#fff' width={24} height={24} />
                </div>
                <div style={styles.fullScreenPhotoWrap}>
                    <img style={styles.fullScreenPhoto} src={this.props.imageURL} alt="" />
                </div>
            </div>
        );
  }
}

const styles={
    photoViewerContainer:{
        zIndex: 9999999,
        position: 'fixed', left: 0, top: 0,
        width: '100vw', height: '100%',
        backgroundColor: 'rgba(0, 0, 0, .9)',
    },
        closeButtonWrap:{
            zIndex: 99999999,
            position: 'absolute', left: 20, top: 20,
        },
        fullScreenPhotoWrap:{
            position: 'relative',
            width: '100%', height: '100%',
            overflow: 'auto',
        },
            fullScreenPhoto:{
                position: 'absolute', left: '50%', top: '50%', transform: 'translate( -50%, -50%)',
                width: '100%', height: 'auto',
            },
}

export default PhotoViewer;
