import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import './App.css';

// import LanguageButton from './components/LanguageButton';
import TabBar from './components/TabBar';
import ModalSheet from './components/ModalSheet';
import Home from './screens/Home';
import Rooms from './screens/Rooms';
import Food from './screens/Food';
import Activity from './screens/Activity';
import CarouselModalSheet from './components/CarouselModalSheet';
import PhotoViewer from './components/PhotoViewer';

//const network = require('./libs/network_speed');

class App extends Component {
  state = {
    internetSpeed: 150,
    showPhoto: true,

    currentScreen: 'home',

    currentLanguage: 'English',
    ModalIsVisible: false,
    modalAssets: {},

    showPhotoViewer: false,
    imageURL: '',
  } 

  componentDidMount(){
    // let photo_folder_size = 7000000; // about 7Mb 
    //network.getDownloadSpeed(window.location.hostname,photo_folder_size).then(speed => {
    //   this.setState({ internetSpeed: Math.floor(photo_folder_size / speed.bps) }); 
    //   console.log('wating time => ' +  Math.floor(photo_folder_size / speed.bps));
   //});
  }

  showModal = ( modalAssets ) => {
    this.setState({ 
      modalAssets: {
        number: modalAssets.number,
        title: modalAssets.title,
        description: modalAssets.description,
        actions: modalAssets.actions,
        defaultButtonLabel: modalAssets.defaultButtonLabel,
      },
      modalIsVisible: true,
    });
  }

  hideModal= () => {
    this.setState({
      modalIsVisible: false, 
      modalAssets: {},
    });
  }

  showCarouselModal = () => {
    this.setState({ modalIsVisible: false, carouselModalIsVisible: true });
  }

  hideCarouselModal = () => {
    this.setState({ carouselModalIsVisible: false });
  }

  handleScreenChange = ( selectedScreen ) => {
    this.setState({ currentScreen: selectedScreen });
  }

  handleShowPhoto = () => {
    if ( this.state.modalIsVisible === true ) this.setState( { modalIsVisible: false });
    if ( this.state.showPhoto === false ) this.setState( { showPhoto: true });
  }
  
  handleShowPhotoViewer = ( imageURL ) => {
    this.setState( { imageURL: imageURL });
    if ( this.state.modalIsVisible === true ) this.setState( { modalIsVisible: false });
    if ( this.state.showPhotoViewer === false ) this.setState( { showPhotoViewer: true });
  }
  closePhotoViewer = () => {
    this.setState( { showPhotoViewer: false });
  }

  render(){
    const { 
      internetSpeed, showPhoto, currentScreen, modalAssets, modalIsVisible, carouselModalIsVisible, imageURL, showPhotoViewer, 
      // currentLanguage
    } = this.state;
    const { showModal, hideModal, showCarouselModal, hideCarouselModal, handleScreenChange, handleShowPhoto, handleShowPhotoViewer } = this;

    return(
      <div className="App">
        <Helmet 
          title='retreat siargao resort'
          meta={[
            {property:"og:url", content: "https://www.retreatsiargao.com/" },
            {property:'site_name', content: 'retreat siargao resort' },
            {property:"og:type", content:"website" },
            {property:"og:title", content: "retreat siargao resort" },
            {property:"og:description", content: "Re-Treat yourself, and Retreat will do the rest." },
            {property:"og:image", content: "https://retreat-eco-web.netlify.com/photos/garden-sunset.jpg" },
            {property:'og:locale', content: 'en_PH' },
            {property:'og:locale:alternate', content: 'ko_KR' },
            {property:'og:locale:alternate', content: 'en_US' },
            {property:'og:locale:alternate', content: 'zh_CN' },
            {property:'og:locale:alternate', content: 'ja_JP' },
          ]}
        />
        {
          this.state.showPhotoViewer &&
          <PhotoViewer 
              closePhotoViewer={this.closePhotoViewer}
              imageURL={this.state.imageURL}
          />
        }
        {/* !modalIsVisible &&
          <LanguageButton 
            currentLanguage={currentLanguage} top={10} right={10} 
            showModal={ showModal }
          />
        */}
        { currentScreen === 'home' && 
          <Home 
            internetSpeed={ internetSpeed }  
            showModal={ showModal } 
            modalAssets={ modalAssets } 
            handleShowPhoto={ handleShowPhoto } 
            handleShowPhotoViewer={ handleShowPhotoViewer }
            imageURL={ imageURL }
            showPhoto={ showPhoto }
            showPhotoViewer={ showPhotoViewer }
          /> 
        }
        { currentScreen === 'rooms' && 
          <Rooms 
            internetSpeed={ internetSpeed }  
            showModal={ showModal } 
            modalAssets={ modalAssets }  
            handleShowPhoto={ handleShowPhoto }
            handleShowPhotoViewer={ handleShowPhotoViewer }
            imageURL={ imageURL }
            showPhoto={ showPhoto }
            showPhotoViewer={ showPhotoViewer } 
          /> 
        }
        { currentScreen === 'food' && 
          <Food 
            showModal={ showModal } 
            modalAssets={ modalAssets }  
            handleShowPhoto={ handleShowPhoto }
            handleShowPhotoViewer={ handleShowPhotoViewer }
            imageURL={ imageURL }
            showPhoto={ showPhoto }
            showPhotoViewer={ showPhotoViewer } 
          /> 
        }
        { currentScreen === 'activity' && 
          <Activity 
            internetSpeed={ internetSpeed }  
            showModal={ showModal } 
            modalAssets={ modalAssets }  
            handleShowPhoto={ handleShowPhoto }
            handleShowPhotoViewer={ handleShowPhotoViewer }
            imageURL={ imageURL } 
            showPhoto={ showPhoto }
            showPhotoViewer={ showPhotoViewer }
          /> 
        } 
        { carouselModalIsVisible &&
          <CarouselModalSheet 
            hideCarouselModal={ hideCarouselModal }
            handleShowPhotoViewer={ handleShowPhotoViewer }
          />
        }
        { modalIsVisible &&
          <ModalSheet
            hideModal={ hideModal }
            handleShowPhoto={ handleShowPhoto } 
            modalAssets={{
              title: modalAssets.title,
              description: modalAssets.description,
              actions: modalAssets.actions,
              defaultButtonLabel: modalAssets.defaultButtonLabel,
              number: modalAssets.number,
            }}
          />
        }
        <TabBar 
          handleScreenChange={ handleScreenChange }
          currentScreen={ currentScreen }
          showModal={ showModal }
          hideModal={ hideModal }
          showCarouselModal={ showCarouselModal }
          modalAssets={ modalAssets }
          internetSpeed={ internetSpeed } 
          showPhoto={ showPhoto } 
          handleShowPhoto={ handleShowPhoto } 
        />
      </div>
    );
  }
}


export default App;
