import React, { Component } from 'react';
import '../styles/styles.scss';
import { FormattedMessage } from 'react-intl';

import Header from '../components/Header';
import Paragraph from '../components/Paragraph';
import Contacts from '../components/Contacts';

const texts = {
  paragraph1: {
    title: <FormattedMessage id='funActivities' defaultMessage='Fun Activities' />,
    description: <FormattedMessage id='funActivitiesDesc' defaultMessage='Are you a fun of water adventure? Then water sports might just relieve you with your pleasures. From Stand-up paddle boarding, Surfing to excursion you will perfectly have a splash! Retreat also offer aquatic activities such as diving, fishing and boating for anyone who loves on immersing in the tropical sea breeze of Retreat Beach front. All these water sports activities can be more enjoyed ideally when the sea condition is good and high tide as well.' />,
  },
  paragraph2: {
    title: <FormattedMessage id='entireSiargaoLandTour' defaultMessage='Entire Siargao Land Tour' />,
    description: [
        <FormattedMessage id='entireSiargaoLandTourDesc1' defaultMessage='Up to 2,500.00 per pax minimum of 6 person good for 7-8 hours' />,
        <FormattedMessage id='entireSiargaoLandTourDesc2' defaultMessage='Exciting adventure to:' />,
        <FormattedMessage id='entireSiargaoLandTourDesc3' defaultMessage='- Magpupungko Rock Pool Formation' />,
        <FormattedMessage id='entireSiargaoLandTourDesc4' defaultMessage='- Sugba Lagoon' />,
        <FormattedMessage id='entireSiargaoLandTourDesc5' defaultMessage='- Maasin River' />,
        <FormattedMessage id='entireSiargaoLandTourDesc6' defaultMessage='Inclusions:' />,
        <FormattedMessage id='entireSiargaoLandTourDesc7' defaultMessage='- Transportation' />,
        <FormattedMessage id='entireSiargaoLandTourDesc8' defaultMessage='- Boat Ride' />,
        <FormattedMessage id='entireSiargaoLandTourDesc9' defaultMessage='- All fees' />,
        <FormattedMessage id='entireSiargaoLandTourDesc10' defaultMessage='- Food and Beverages' />,
        <FormattedMessage id='entireSiargaoLandTourDesc11' defaultMessage='- Parking' />,
        <FormattedMessage id='entireSiargaoLandTourDesc12' defaultMessage='- Paddle Board' />,
    ],
    
  }
}
class Activity extends Component{

  render(){

    return (
      <div className="screen-container">
        <Header />
        <Paragraph
          title={texts.paragraph1.title}
          description={texts.paragraph1.description}
        />
        <Paragraph
          topTriangleColor='#fff' bottomTriangleColor='#000'
          gradientAngle='176.4'
          colorCode1='#19705F' colorPosition1='0'
          colorCode2='#219B7D' colorPosition2='23.46'
          colorCode3='#4DB594' colorPosition3='64.22'
          colorCode4='#68AB7F' colorPosition4='100'
          title={texts.paragraph2.title}
          titleColor='#fff'
          description={texts.paragraph2.description}
          descriptionColor='#fff'
        />
        <Contacts />
      </div>
    );
  }
}

export default Activity;
