import React, { Component } from 'react';
import '../styles/header.scss';

import { ReactComponent as LogoText } from '../assets/logo-text.svg';
import { ReactComponent as Logo } from '../assets/logo.svg';

class Header extends Component {
  
  onLogoPress = () => {
    
  }
  
  render(){
    const{ onLogoPress } = this;
    const deg = -( Math.atan( window.innerWidth, 50 )  * Math.PI + 180 ); 
    const bottomTriangleColor = '#fff';

    return(
      <div className="header-container" onClick={ () => onLogoPress() } >
          <Logo fill='#fff' width={50} height={50} style={{margin: 10, marginBottom: 0}}/>
        <div className="title-text">
            <LogoText width={180} fill='#fff' />
        </div>
        <div className='bottom-triangle-screen' 
            style={{ 
                background: `linear-gradient( ${deg}deg, transparent 50%, ${bottomTriangleColor} 50% 100% )`
        }}/>
      </div>


    );
  }
}


export default Header;
