import React, { Component } from 'react';
import '../styles/modal.scss';

import { FormattedMessage } from 'react-intl';

class ModalSheet extends Component {
    state={
        modalStyle: {},
        panelStyle: {},
    }

    componentDidMount(){
        this.setState({
            modalStyle: { animationName: 'appear', backgroundColor: 'rgba(0,0,0,.7)' },
            panelStyle: { animationName: 'moveIn', backgroundColor: 'rgba(0,0,0,.8)' }
        });
    }

    onBack = () => {
        this.setState({
            modalStyle: { animationName: 'disappear', backgroundColor: 'transparent' },
            panelStyle: { animationName: 'moveOut', backgroundColor: 'transparent' }
        });
        this.props.hideModal();
    }

    render(){
        const { modalAssets } = this.props;
        const { modalStyle, panelStyle } = this.state;

        return(
            <div style={modalStyle} className='modal-container'>
                <div style={panelStyle} className='panel-wrap'>
                    <div className='text-area-wrap'>
                        <p className='title-text'>
                            {modalAssets.number && <span className='time-number'>{modalAssets.number}</span>}
                            {modalAssets.title}
                        </p>
                        <p className='description-text'>
                            {modalAssets.description}
                        </p>
                    </div>
                    <div className='buttons-wrap'>
                        { modalAssets.component && modalAssets.component }
                        { modalAssets.actions &&
                            modalAssets.actions.map( ( action ) => (
                                <div className='action-button-wrap'>
                                    <div className='action-button' onClick={()=>action.onPress()}>
                                        { action.icon && 
                                            <div className='action-icon'>
                                                { action.icon }
                                            </div>
                                        }
                                        { action.label &&
                                            <p className='action-text'>
                                                { action.label }
                                            </p>
                                        }
                                    </div>
                                    { action.description &&
                                        <p className='action-description'>
                                            { action.description }
                                        </p>
                                    }
                                </div>
                            ))    
                        }
                        { !modalAssets.noDefaultButton &&
                            <div className='default-button-wrap'>
                                <div className='default-button' onClick={()=>this.onBack()}>
                                    <p className='default-text'>
                                        {
                                            modalAssets.defaultButtonLabel? modalAssets.defaultButtonLabel : 
                                            <FormattedMessage id='cancel' defaultMessage='Cancel' />
                                        }
                                    </p>
                                </div>
                                { modalAssets.defaultButtonDescription &&
                                    <p className='default-description'>
                                        { modalAssets.defaultButtonDescription }
                                    </p>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        );

  }
}


export default ModalSheet;
