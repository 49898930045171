import React, { Component } from 'react';
import '../styles/paragraph.scss';
import '../styles/carousel.css';

import { Carousel } from 'react-responsive-carousel';
import ShowPhotoButton from './ShowPhotoButton';

class Paragraph extends Component {
    static defaultProps={
        topTriangleColor: '',
        bottomTriangleColor: '',

        gradientAngle: '',
        colorCode1: '',
        colorPosition1: '',
        colorCode2: '',
        colorPosition2: '',
        colorCode3: '',
        colorPosition3: '',
        colorCode4: '',
        colorPosition4: '',

        title: '',
        titleColor: '',
        description: '',
        descriptionColor: '',
        photoFirst: false,
    }

    renderImages = () => {
        const{ showPhoto, showPhotoButtonBackgroundColor, internetSpeed, photos, showModal, handleShowPhoto, handleShowPhotoViewer } = this.props;

        if ( photos ) {
            if ( showPhoto ) {
                return(
                    <Carousel
                        showThumbs={false} 
                        autoPlay={true} infiniteLoop={true}
                        onClickItem={ ( currentImageIndex ) => handleShowPhotoViewer( photos[currentImageIndex].url ) } 
                    >
                        {
                            photos.map( ( image ) => (
                                <div>
                                    <img src={image.url} alt="" />
                                    <p>{image.label}</p>
                                </div>
                            ))
                        }
                    </Carousel>
                )
            } else {
                return(
                    <ShowPhotoButton 
                        internetSpeed={internetSpeed}
                        showModal={showModal}
                        handleShowPhoto={handleShowPhoto}
                        backgroundColor={showPhotoButtonBackgroundColor}
                    />
                )
            }
        } else {
            return 
        }
    }

    render(){
        const{ 
            photoFirst,
            topTriangleColor, bottomTriangleColor,
            gradientAngle,
            colorCode1, colorPosition1,
            colorCode2, colorPosition2,
            colorCode3, colorPosition3,
            colorCode4, colorPosition4,
            title, description,
            titleColor, descriptionColor,
        } = this.props;

        const paddingTop = topTriangleColor && 70;
        const deg = -( Math.atan( window.innerWidth, 50 )  * Math.PI + 180 ); 

        return(
            <div className="paragraph-container"
                style={{
                    background: `linear-gradient(${gradientAngle}deg, ${colorCode1} ${colorPosition1}%, ${colorCode2} ${colorPosition2}%, ${colorCode3} ${colorPosition3}%, ${colorCode4} ${colorPosition4}%)`
                }}
            >
                <div className='top-triangle-screen' 
                    style={{
                        background: `linear-gradient( ${deg}deg, ${topTriangleColor} 50%, transparent 50% 100% )`
                    }}
                />
                <div className="title-wrap" style={{ paddingTop }}>
                    <p style={{ color: titleColor }}>{title}</p>    
                </div>  
                {
                    photoFirst &&
                    <div className='carousel-wrap'>   
                        { this.renderImages() }
                    </div>
                }   
                <div className="description-wrap">
                    { description.length?
                        description.map( ( item ) => 
                            <p style={{ color: descriptionColor }}>
                                { item }
                            </p>        
                        ):
                        <p style={{ color: descriptionColor }}>
                            {description}
                        </p>    
                    }
                </div>
                {
                    !photoFirst &&
                    <div className='carousel-wrap'>   
                        { this.renderImages() }
                    </div>
                }   
                <div className='bottom-triangle-screen' 
                    style={{ 
                        background: `linear-gradient( ${deg}deg, transparent 50%, ${bottomTriangleColor} 50% 100% )`
                }}/>
            </div>
    );
  }
}

export default Paragraph;
