import React, { Component } from 'react';
import '../styles/styles.scss';

import { ReactComponent as PhotosIcon } from '../assets/photo.svg';
import { FormattedMessage } from 'react-intl';

class ShowPhotoButton extends Component {
    
    onPress = () => {
        const { handleShowPhoto, showModal, internetSpeed } = this.props;

        showModal({
            number: internetSpeed,
            title: <FormattedMessage id='seconds' defaultMessage='seconds' />,
            description: <FormattedMessage id='internetSpeedDesc' defaultMessage="Due to slow Internet speed, it will a while to load all the photos. Do you still want to load the photos?" />,
            actions:[
                { 
                    label: <FormattedMessage id='agree' defaultMessage='Yes, I can wait.' />,
                    onPress: handleShowPhoto,
                }
            ],
            
        });
    }

    render(){
        const { backgroundColor } = this.props;
        const { onPress } = this;

        let finalBackgroundColor = 'rgba(0, 0, 0, .4)';
        if ( backgroundColor ) finalBackgroundColor = backgroundColor;

        const containerStyle = {
            ...styles.container, backgroundColor: finalBackgroundColor,
        }

        return (
            <div style={containerStyle} onClick={ () => onPress() }>
                <PhotosIcon style={styles.icon} />
                <p style={styles.label}>
                    <FormattedMessage id='photos' defaultMessage='Photos' />
                </p>
            </div>
        );
    }

}

const styles={
    container: {
        zIndex: 9999, marginTop: 10,
        display: 'inline-block', position: 'relative',
        width: 50, height: 50, borderRadius: 16,
        cursor: 'pointer',
    },
        icon: {
            marginTop: 4,
            width: 20, height: 30,
        },
        label: {
            width: 60, 
            position: 'absolute', bottom: 6, left: '50%',
            transform: 'translate(-50%, 0)',
            fontSize: 10, textAlign: 'center',
            color: '#fff', 
        },
}


export default ShowPhotoButton;
