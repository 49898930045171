import React, { Component } from 'react';
import '../styles/styles.scss';
import { FormattedMessage } from 'react-intl';

import Header from '../components/Header';
import Paragraph from '../components/Paragraph';
import Contacts from '../components/Contacts';

import { ReactComponent as PhoneIcon } from '../assets/phone.svg';
import { ReactComponent as MailIcon } from '../assets/mail.svg';
import { ReactComponent as MapIcon } from '../assets/map.svg';

const siteURL = 'https://retreat-eco-web.netlify.com/photos/';
const contents = {
  paragraph1: {
    title: <FormattedMessage id='bestRoomsInSiargao' defaultMessage='Best room in Siargao Island' />,
    photos:[
      { url: siteURL+'room-01.jpg', label: '' },
      { url: siteURL+'room-02.jpg', label: '' },
      { url: siteURL+'room-03.jpg', label: '' },
      { url: siteURL+'room-04.jpg', label: '' },
      { url: siteURL+'room-05.jpg', label: '' },
    ],
    description: [
      <FormattedMessage id='bestRoomsInSiargaoDesc' defaultMessage='Experience one of the most exquisite suite accommodation you’ve ever stay in the beautiful Island of Siargao. Retreat Siargao Resort offers a luxurious suite setting accommodation, altering with local flair that designed to inspire rest and relaxation. The sleek and modern guest rooms create a feeling of home with peaceful color tones and refined partisan walls in the corner. All rooms are uniform in size and design.' />,

      <FormattedMessage id='itFeatures' defaultMessage='It features' />,
      <FormattedMessage id='itFeaturesDesc1' defaultMessage='Two beds comfortably sleep with three people, long sofas and coffee table if you want to take a long break.' />,
      <FormattedMessage id='itFeaturesDesc2' defaultMessage='Each rooms has fridge filled with beer, soft drinks and other drinks in can.' />,
      <FormattedMessage id='itFeaturesDesc3' defaultMessage='- Water dispenser  with extra cups.' />,
      <FormattedMessage id='itFeaturesDesc4' defaultMessage='- Internet router in each room.' />,
      <FormattedMessage id='itFeaturesDesc5' defaultMessage='- 50 inch of HD flat screen TV.' />,
      <FormattedMessage id='itFeaturesDesc6' defaultMessage='- Quality Sound Bar Speaker.' />,
      <FormattedMessage id='itFeaturesDesc7' defaultMessage='- 2 units AC for Bed and living area.' />,
      <FormattedMessage id='itFeaturesDesc8' defaultMessage='- Each room has Coffee Maker.' />,
      <FormattedMessage id='itFeaturesDesc9' defaultMessage='- Spacious Shower. Hair Dryer.' />,
      <FormattedMessage id='itFeaturesDesc10' defaultMessage='- Hair Dryer.' />,
      <FormattedMessage id='itFeaturesDesc11' defaultMessage='- Modern Lavatory.' />,
    ]
  },
  paragraph2: {
    title: <FormattedMessage id='surfDorm' defaultMessage='Surf Dorm' />,
    photos:[
      { url: siteURL+'surf-dorm-01.jpg', label: '' },
      { url: siteURL+'surf-dorm-02.jpg', label: '' },
      { url: siteURL+'surf-dorm-03.jpg', label: '' },
      { url: siteURL+'surf-dorm-04.jpg', label: '' },
      { url: siteURL+'surf-dorm-05.jpg', label: '' },
      { url: siteURL+'surf-dorm-06.jpg', label: '' },
      { url: siteURL+'surf-dorm-07.jpg', label: '' },
    ],
  }
}
class Rooms extends Component{

  onBookButtonPress = () => {
    const { showModal } = this.props;
    showModal({
      title: <FormattedMessage id='contact' defaultMessage ='Contacts' />,
      description: <FormattedMessage id='contactDesc' defaultMessage='We love to hearing from you. Please ask us any question. You also could reach us by...' />,
      actions:[
          { 
              icon: <PhoneIcon className='action-icon' 
                  style={{widht: 20, height: 17, marginTop: 12}} />,
              description: 'Globe/TM: +63-915-357-2283',
              onPress: () => { window.open('tel:639153572283') }
          },
          { 
              icon: <MailIcon className='action-icon' 
                  style={{width: 19, height: 12, marginTop: 15}} />,
              description: 'askretreat@gmail.com',
              onPress: () => { window.open('mailto:askretreat@gmail.com') }
          },
          { 
              icon: <MapIcon className='action-icon' 
                  style={{widht: 12, height: 17, marginTop: 12}} />,
              description: 'Brgy. Malinao, General Luna, Siargao Island, Surigao del Norte, 8149 Philippines',
              onPress: () => { window.open('https://goo.gl/maps/vRLDYzbd4aYzMvaq9', '_blank') }
          },
      ]
    });
  }

  render(){
    const { internetSpeed, showModal, handleShowPhoto, showPhoto, handleShowPhotoViewer } = this.props;
    const { onBookButtonPress } = this;
    
    return (
      <div className="screen-container rooms-container">
        <Header />
        <div className='button-wrap'>
          <div className="book-button" onClick={ () => onBookButtonPress() }>
            <FormattedMessage id='bookingInquiry' defaultMessage='Booking Inquiry' />
          </div>
        </div>  
        <Paragraph
          title={ contents.paragraph1.title }
          description={ contents.paragraph1.description }
          photoFirst
          showPhoto={ showPhoto }
          photos={ contents.paragraph1.photos }
          handleShowPhoto={ handleShowPhoto }
          handleShowPhotoViewer={ handleShowPhotoViewer }
          internetSpeed={ internetSpeed }
          showModal={ showModal }
        />
        <Paragraph
          topTriangleColor='#fff' bottomTriangleColor='#000'
          gradientAngle='176.4'
          colorCode1='#19705F' colorPosition1='0'
          colorCode2='#219B7D' colorPosition2='23.46'
          colorCode3='#4DB594' colorPosition3='64.22'
          colorCode4='#68AB7F' colorPosition4='100'

          title={contents.paragraph2.title}
          titleColor='#fff'

          photoFirst
          showPhoto={ showPhoto }
          photos={ contents.paragraph2.photos }
          handleShowPhoto={ handleShowPhoto }
          handleShowPhotoViewer={ handleShowPhotoViewer }
          internetSpeed={ internetSpeed }
          showModal={ showModal }
          description={contents.paragraph2.description}
          descriptionColor='#fff'
        />
        <Contacts />
      </div>
    );
  }

}

export default Rooms;
