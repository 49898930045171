import React, { Component } from 'react';
import '../styles/styles.scss';
import { FormattedMessage } from 'react-intl';

import BigHeader from '../components/BigHeader';
import Paragraph from '../components/Paragraph';
import Contacts from '../components/Contacts';

const siteURL = 'https://retreat-eco-web.netlify.com/photos/';
const contents = {
  paragraph1: {
    title: <FormattedMessage id='aboutUs' defaultMessage='About Us' />,
    // description: <FormattedMessage id='aboutUsDesc' defaultMessage='Surrounded by pristine beaches and islands, “Retreat” is a boutique resort that offers a getaway comfort and adventure from the usual daily life of the city. Be greeted by the stunning beachfront and the calm sea breeze as you unwind in your suite setting accommodation, get mesmerize with infused Korean-Japanese cuisines and local delights of Siargao that will surely complement your lifestyle. Loosen-up with vibrant entertainment and experience the spirit of service as you enjoy your whole break. You will be excited to wake up and indulge the day full of fun and adventures that awaits you. Within the solace of the resorts vicinity, you can enjoy stand-up paddling, surfing, fishing, boating, and diving nailed with breathtaking views of the ocean. You may also laze in tranquility at the infinity pool, chill by the beachfront, or drench yourself with nature’s Sandy Cove beauty at the viewing deck. All these and more can be enjoyed at Retreat Siargao.' />,
    photos: [
      { url: siteURL+'resort-sign.jpg', label: '' },
      { url: siteURL+'beach-day.jpg', label: '' },
      { url: siteURL+'garden-day-01.jpg', label: '' },
      { url: siteURL+'garden-morning-01.jpg', label: '' },
      { url: siteURL+'resort-day.jpg', label: '' },
      { url: siteURL+'beach-sunset-01.jpg', label: '' },
      { url: siteURL+'garden-sunset.jpg', label: '' },
      { url: siteURL+'resort-evening.jpg', label: '' },
      { url: siteURL+'bar.jpg', label: '' },
      { url: siteURL+'room-01.jpg', label: '' },
      { url: siteURL+'room-03.jpg', label: '' },
      // { url: siteURL+'pool-01.jpg', label: '' },
      // { url: siteURL+'pool-02.jpg', label: '' },
      { url: siteURL+'restaurant-01.jpg', label: '' },
      { url: siteURL+'restaurant-02.jpg', label: '' },
    ],
    description: [
      <FormattedMessage id="aboutUsDesc01" defaultMessage ="* Retreat Siargao is a white sand beach front resort." />,
      <FormattedMessage id="aboutUsDesc02" defaultMessage ="* Retreat has 16 beach front suites that are 60 square meters with a separate bedroom and living area all facing Malinao bay with beach and island views." />,
      <FormattedMessage id="aboutUsDesc03" defaultMessage ="* Wake up to a beautiful sunrise with ocean views from our suites." />,
      <FormattedMessage id="aboutUsDesc04" defaultMessage ="* Enjoy peaceful walks on the 2km long Malinao Beach with warm ocean inviting you to swim." />,
      <FormattedMessage id="aboutUsDesc05" defaultMessage ="* Walking distance to Malinao village for local shopping.
      " />,
      <FormattedMessage id="aboutUsDesc06" defaultMessage ="* General Luna town center and Cloud 9 are just a few minutes away." />,
      <FormattedMessage id="aboutUsDesc07" defaultMessage ="* Retreat also offers group accomodations on the 2nd  floor of the main building.  The largest room has 8 beds with pool and ocean views with an additional 6 group rooms with garden views." />,
      <FormattedMessage id="aboutUsDesc08" defaultMessage ="* Retreat has boats available just 5 -10 minutes ride to most surfing destinations. Private surfing lessons are also available." />,
      <FormattedMessage id="aboutUsDesc09" defaultMessage ="* Island hopping and many other ocean activities including land tours to popular island destinations are available." />,
      <FormattedMessage id="aboutUsDesc10" defaultMessage ="* Restaurant with pool and ocean views offering Kang-Nam service breakfast, lunch and dinner, room and beach front services are available." />,
      <FormattedMessage id="aboutUsDesc11" defaultMessage ='* Inquiry for "All Inclusive" offer : meals, drinks and activities are all included.' />,
      <FormattedMessage id="aboutUsDesc12" defaultMessage ="* 40 minutes from the airport and 25 minutes to the port." />,
    ],
  },
  paragraph2: {
    title: <FormattedMessage id='messageFromOwnerJason' defaultMessage='Message from owner Jason' />,
    // description: <FormattedMessage id='messageFromJasonDesc' defaultMessage='Like many of you, I also have been spending almost all my life working in the Metropolitan. As a Surfer and Restaurateur, I have had the privilege to visit many Surfing destinations and enjoy various local cuisines around the world. Yes, I have fallen in love with Siargao since I discovered this incredible island in 2016. Whatever your expectation of island vacation is, I know that Siargao can fulfill your wishs and we hope to be at your service of accomplishing that. Just “Re-Treat yourself, and Retreat will do the rest”.' />,
    description: [
      <FormattedMessage id="messageFromJasonDesc01" defaultMessage ="The pandemic has impacted all of our lives as we have known it.  Many of us dream about that peaceful escape to a quiet beach in a relaxing tropical environment.
      " />,
      <FormattedMessage id="messageFromJasonDesc02" defaultMessage ="Retreat Resort located in a quaint small town on a beautiful Asia Pacific island is opening back up to provide the perfect get away with personalized service just for you with uncrowded white sand beaches to relax and sunbathe or simply enjoy the day at our infinity pool, read a book and have some lunch because you and your family deserve it.
      " />,
      <FormattedMessage id="messageFromJasonDesc03" defaultMessage ="Treat yourself from stresses of everyday life and relax in our beautiful Retreat Resort." />,
    ],
    photos: [
      { url: siteURL+'crew-01.jpg', label: '' },
      { url: siteURL+'crew-02.jpg', label: '' },
    ],
  }
}

class Home extends Component{
  state={ 
    currentImageIndex: null,
  }

  render(){
    const { internetSpeed, showModal, handleShowPhoto, showPhoto, handleShowPhotoViewer } = this.props;

    return (
      <div className="screen-container">
        <BigHeader 
          showModal={ showModal } 
        />
        <Paragraph
          title={ contents.paragraph1.title }
          description={ contents.paragraph1.description }
          photoFirst={ true }
          showPhoto={ showPhoto }
          photos={ contents.paragraph1.photos }
          handleShowPhoto={ handleShowPhoto }
          handleShowPhotoViewer={ handleShowPhotoViewer }
          internetSpeed={ internetSpeed }
          showModal={ showModal }
        />
        <Paragraph
          title={contents.paragraph2.title}
          description={contents.paragraph2.description}

          titleColor='#fff'
          descriptionColor='#fff'
          topTriangleColor='#fff' bottomTriangleColor='#000'
          gradientAngle='176.4'
          colorCode1='#19705F' colorPosition1='0'
          colorCode2='#219B7D' colorPosition2='23.46'
          colorCode3='#4DB594' colorPosition3='64.22'
          colorCode4='#68AB7F' colorPosition4='100'

          showPhoto={ showPhoto }
          photos={ contents.paragraph2.photos }
          handleShowPhoto={ handleShowPhoto }
          handleShowPhotoViewer={ handleShowPhotoViewer }
          // showPhotoButtonBackgroundColor='#000'
          internetSpeed={ internetSpeed }
          showModal={ showModal }
        />
        <Contacts />
      </div>
    );
  }

}

export default Home;
