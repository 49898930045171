import React, { Component } from 'react';
import '../styles/carousel.css';

import { FormattedMessage } from 'react-intl';
import { Carousel } from 'react-responsive-carousel';

const siteURL = 'https://retreat-eco-web.netlify.com/photos/';
const photos = [
    { url: siteURL+'crew-01.jpg', label: '' },
    { url: siteURL+'crew-02.jpg', label: '' },
    { url: siteURL+'bar.jpg', label: '' },
    { url: siteURL+'beach-day.jpg', label: '' },
    { url: siteURL+'beach-sunset-01.jpg', label: '' },
    // { url: siteURL+'beach-sunset-02.jpg', label: '' },
    // { url: siteURL+'cloud9.jpg', label: '' },
    { url: siteURL+'garden-day-01.jpg', label: '' },
    { url: siteURL+'garden-day-02.jpg', label: '' },
    { url: siteURL+'garden-evening.jpg', label: '' },
    { url: siteURL+'garden-morning-01.jpg', label: '' },
    // { url: siteURL+'garden-morning-02.jpg', label: '' },
    { url: siteURL+'garden-sunset.jpg', label: '' },
    { url: siteURL+'pool-01.jpg', label: '' },
    { url: siteURL+'pool-02.jpg', label: '' },
    { url: siteURL+'resort-day.jpg', label: '' },
    { url: siteURL+'resort-evening.jpg', label: '' },
    // { url: siteURL+'resort-night.jpg', label: '' },
    { url: siteURL+'resort-sign.jpg', label: '' },
    { url: siteURL+'restaurant-01.jpg', label: '' },
    { url: siteURL+'restaurant-02.jpg', label: '' },
    { url: siteURL+'room-01.jpg', label: '' },
    { url: siteURL+'room-02.jpg', label: '' },
    { url: siteURL+'room-03.jpg', label: '' },
    { url: siteURL+'room-04.jpg', label: '' },
    { url: siteURL+'room-05.jpg', label: '' },
];
class CarouselModalSheet extends Component {

    onBack = () => {
        this.props.hideCarouselModal();
    }

    render(){
        return(
            <div style={styles.screen}>
                <div style={styles.container}>
                    <div style={styles.carouselWrap}>
                        <Carousel
                            autoPlay={true}
                            showArrows={true} 
                            useKeyboardArrows={true}
                            stopOnHover={true}
                            onClickItem={ ( currentImageIndex ) => this.props.handleShowPhotoViewer( photos[currentImageIndex].url )} 
                        >
                            {
                                photos.map( ( item ) => (
                                    <div style={styles.carouselItem}>
                                        <img style={styles.photo} src={item.url} alt="" />
                                        <p style={styles.label}>{item.label}</p>
                                    </div>
                                ))
                            }
                        </Carousel>
                    </div>
                    <div style={styles.buttonsWrap}>
                        <div style={styles.button} 
                            onClick={()=>this.onBack()}>
                            <p style={styles.buttonText}>
                                <FormattedMessage id='close' defaultMessage='Close' />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const styles={
    screen: {
        zIndex: 999999,
        position: 'fixed', left: 0, top: 0,
        width: '100vw', height: '100%',
        backgroundColor: 'rgba(0, 0, 0, .9)',
    },
    container: {
        position: 'relative',
        height: '100%', width: 'auto', 
        margin: 'auto',
        minWidth: 375, maxWidth: 1000,
        textAlign: 'center',
        paddingTop: 20,
    },
      
        buttonsWrap:{
            position: 'absolute', left: '50%', transform: 'translate( -50%, 0 )', bottom: 40,
            width: 'calc(100% - 20px)',
        },
            button:{
                width: '100%', maxWidth: 1242/2-40,
                margin: 'auto',
                height: 50, borderRadius: 25,
                cursor: 'pointer', textAlign: 'center',
                backgroundColor: '#222',
            },
                buttonText: {
                    display: 'inline-block', marginTop: 16,
                    fontSize: 16, lineHeight: '18px', color: '#fff',
                },
}
export default CarouselModalSheet;
