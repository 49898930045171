import React, { Component } from 'react';
import '../styles/styles.scss';
import { FormattedMessage } from 'react-intl';

import Header from '../components/Header';
import Paragraph from '../components/Paragraph';
import Contacts from '../components/Contacts';

const texts = {
  paragraph1: {
    title: <FormattedMessage id='gangNamKitchen' defaultMessage='Gangnam Kitchen' />,
    description: <FormattedMessage id='gangNamKitchenDesc' defaultMessage='Experience one Satiates your cravings and get fascinated with custom cuisines that will surely complement your lifestyle. Experience a distinct dine out at Retreat Sandy Cove Siargao, with menus confounding fresh ingredients which comprise of dishes that is perfect to balance your appetite. Menus are blended with Infuse Korean Cuisines, Inventive Appetizer highlighted with Banchan and the homey Local delights of Siargao Island.

    Just seat back Eat, Loosen-up and Relax! And retreat will do the rest.' />,
  },
  paragraph2: {
    title: <FormattedMessage id='pauroyAndSojuBar' defaultMessage='Pauroy and Soju Bar' />,
    description: <FormattedMessage id='pauroyAndSojuBarDesc' defaultMessage="Never drunk Soju? Then you know nothing about Korean culture. Never drunk Pauroy? Then you've never been here in the island." />,
  }
}
class Food extends Component{

  render(){

    return (
      <div className="screen-container">
        <Header />
        <Paragraph
          title={texts.paragraph1.title}
          description={texts.paragraph1.description}
        />
        <Paragraph
          topTriangleColor='#fff' bottomTriangleColor='#000'
          gradientAngle='176.4'
          colorCode1='#19705F' colorPosition1='0'
          colorCode2='#219B7D' colorPosition2='23.46'
          colorCode3='#4DB594' colorPosition3='64.22'
          colorCode4='#68AB7F' colorPosition4='100'
          title={texts.paragraph2.title}
          titleColor='#fff'
          description={texts.paragraph2.description}
          descriptionColor='#fff'
        />
        <Contacts />
      </div>
    );
  }

}

export default Food;
