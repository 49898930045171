import React, { Component } from 'react';
import '../styles/big-header.scss';

import { FormattedMessage } from 'react-intl';
import { ReactComponent as LogoText } from '../assets/logo-text.svg';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { ReactComponent as PhoneIcon } from '../assets/phone.svg';
import { ReactComponent as MailIcon } from '../assets/mail.svg';
import { ReactComponent as MapIcon } from '../assets/map.svg';

class BigHeader extends Component {
  
  onBookButtonPress = () => {
    const { showModal } = this.props;
    showModal({
      title: <FormattedMessage id='contact' defaultMessage ='Contacts' />,
      description: <FormattedMessage id='contactDesc' defaultMessage='We love to hearing from you. Please ask us any question. You also could reach us by...' />,
      actions:[
          { 
              icon: <PhoneIcon className='action-icon' 
                  style={{widht: 20, height: 17, marginTop: 12}} />,
              description: 'Globe/TM: +63-915-357-2283',
              onPress: () => { window.open('tel:639153572283') }
          },
          { 
              icon: <MailIcon className='action-icon' 
                  style={{width: 19, height: 12, marginTop: 15}} />,
              description: 'askretreat@gmail.com',
              onPress: () => { window.open('mailto:askretreat@gmail.com') }
          },
          { 
              icon: <MapIcon className='action-icon' 
                  style={{widht: 12, height: 17, marginTop: 12}} />,
              description: 'Brgy. Malinao, General Luna, Siargao Island, Surigao del Norte, 8149 Philippines',
              onPress: () => { window.open('https://goo.gl/maps/vRLDYzbd4aYzMvaq9', '_blank') }
          },
      ]
    });
  }

  render(){
    const { onBookButtonPress } = this;
    const deg = -( Math.atan( window.innerWidth, 50 )  * Math.PI + 180 ); 
    const bottomTriangleColor = '#fff';

    return(
      <div className="big-header-container">
        <div className="navigation-wrap">
          {/* <div className="left-wrap">
            <a href={originalURL} target='_blank' className="button">
              <FormattedMessage id='visitOriginalWebsite' defaultMessage='visit original website' />
            </a>
          </div> */}
          {/* <div className="right-wrap">
            <a href="" className="korean-button"/>
          </div> */}
        </div>
    
        <div className="title-wrap">
          <div>
              <Logo fill='#fff' width={120} height={120}/>
          </div>

          <p className="title-text">
            <LogoText width={220} fill='#00467B' />
          </p>
          <p className="subtitle-text">
            <FormattedMessage
              id="siteSubtitle"
              defaultMessage="Re-treat yourself, and Retreat will do the rest."
            />
          </p>
        </div> 

        <div className='button-wrap'>
          <div className="book-button" onClick={ () => onBookButtonPress() }>
            <FormattedMessage id='bookingInquiry' defaultMessage='Booking Inquiry' />
          </div>
        </div>   
        <div className='bottom-triangle-screen' 
            style={{ 
                background: `linear-gradient( ${deg}deg, transparent 50%, ${bottomTriangleColor} 50% 100% )`
        }}/>
      </div>
    );
  }
}


export default BigHeader;
